import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import CardTypeC from "../../components/Cards/Screen 1/CardTypeC";
import CardTypeA from "../../components/Cards/Screen 1/CardTypeA";
import CardTypeB from "../../components/Cards/Screen 1/CardTypeB";
import StickyHeadTableNonPaginated from "../../components/Paginated Table/UnpaginatedTable";
import dtStatic from "../../NewData";
import { useUser } from "../../context/user/index";
import { CLIENT } from "../../components/common/Navbar/constants";
import worldMap from "../../assets/img/World Map.png";
import { getInitials } from "../../utils";

export const HomePage = () => {
  const { userName } = useUser();
  const theme = useTheme();
  const { userDetails, setUserDetails } = useUser();

  return (
    <Stack
      sx={{
        height: "auto",
        width: "auto",
        backgroundColor: theme.palette.secondaryColors[100],
      }}
    >
      <Stack
        sx={{
          position: "relative",
          display: "flex",
          color: theme.palette.baseColors.white,
          height: "15rem",
          paddingLeft: "2rem",
          flexDirection: "row",
          backgroundColor: theme.palette.baseColors.black,
          backgroundImage: `url('${worldMap}')`,
          backgroundPositionX: "right",
          backgroundSize: "auto 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* <Box sx={{
          position: "absolute",
          right: 0,
          width: 'auto',
          height: '100%'
        }}>
          <img
            src={worldMap}
            alt='ImageLoad'
            width='100%'
            height='100%'
          />
        </Box> */}
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              marginBottom: "0vh",
              fontSize: "1.125rem",
              fontWeight: "500",
            }}
          >
            {CLIENT}
          </Typography>
          <Typography
            style={{
              marginTop: "0",
              textTransform: "capitalize",
              fontSize: "2.25rem",
              fontWeight: "700",
            }}
          >
            Welcome, {userName ? `${userName}` : "user"}
          </Typography>
        </Box>
      </Stack>
      <Box
        sx={{
          display: "flex",
          padding: "2rem",
          height: "auto",
          alignItems: "stretch",
        }}
      >
        <Stack
          sx={{
            width: "75%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "stretch",
            maxHeight: "48rem",
          }}
        >
          <div>{window.API_URL.REACT_APP_SSO_BASE_URL}</div>
          <div>{window.API_URL.REACT_APP_SSO_REDIRECT_URI}</div>
          <div>{window.API_URL.REACT_APP_SSO_CLIENT_ID}</div>
          <div>{window.API_URL.REACT_APP_SSO_JUMP_URL}</div>
          <div>{window.API_URL.REACT_APP_SSO_POST_REDIRECT_URL}</div>
          <Box
            sx={{
              maxHeight: "21rem",
              width: "100%",
              display: "flex",
              flex: "1",
            }}
          >
            <Stack
              sx={{
                width: "48%",
                minHeight: "100%",
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CardTypeA
                heading="Global Tax Management Dashboards"
                content="View the global dashboards to help facilitate decisions and compliance across your tax jurisdictions."
                linkLabel="View Dashboard"
                linkTo="/global-dashboard"
              />
              <br />
              <CardTypeA
                heading="Obligations "
                content="View all your tax obligations across the range of entities you are responsible for."
                linkLabel="View Obligations"
                linkTo="/looker-test"
              />
            </Stack>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                minHeight: "100%",
                ml: "auto",
              }}
            >
              <CardTypeB />
            </Box>
          </Box>
          <Box
            sx={{
              maxHeight: "20%",
              width: "100%",
              display: "flex",
              mt: "1.5rem",
            }}
          >
            <StickyHeadTableNonPaginated
              columns={dtStatic.columns}
              checks={false}
              rows={dtStatic.rows}
            />
          </Box>
        </Stack>

        <Box
          sx={{
            maxHeight: "48rem",
            minHeight: "48rem",
            width: "23%",
            marginLeft: "auto",
            display: "flex",
            alignSelf: "stretch",
          }}
        >
          <CardTypeC />
        </Box>
      </Box>
    </Stack>
  );
};
